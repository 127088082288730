import React from "react";
import style from "./Success.module.css";
import SuccessImg from "../../assests/success .png";
import { useNavigate } from "react-router-dom";


function Success() {
  const navigate = useNavigate();

  return (
    <div className={style.Container}>
      <div className={style.successCard}>
       <div className={style.cardBody}>
            <div className={style.Cardheading}>
              <p>Your payment has been</p>
              <h3>Successful !</h3>
            </div>
    
            <div className={style.SuccessImg}>
              <img src={SuccessImg} alt="Success Img " />
            </div>
    
            <div className={style.msg}>
              <p> Thank you for subscribing to GrammarLocker. </p>
              <p> Your subscription is now active. </p>
            </div>
    
            <div className={style.welcome}>
              <p>Welcome Aboard ! </p>
    
              <button onClick={() => navigate("/subscribe/ready")}>Done</button>
            </div>
       </div>
      </div>
    </div>
  );
}

export default Success;
