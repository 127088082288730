import React, { useState } from "react";
import style from "./Profile.module.css";
import ProfileImage from "../../assests/Profile-PNG-File.png";
import { MdModeEdit } from "react-icons/md";
import SuccessImage from "../../assests/PasswordSuccess.png";

const Profile = () => {
  const [editMode, setEditMode] = useState(false);
  const [profileData, setProfileData] = useState({
    firstName: "John",
    lastName: "Doe",
    userName: "Johndoe132",
    country: "India",
    email: "Johndoe13002@gmail.com",
  });
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [contentShow, setContentShow] = useState("emailSection");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSendEmail = () => {
    if (!email) {
      setError("Email is required.");
      return;
    }
    setError("");
    setContentShow("otpSection");
  };

  const handleVerifyOtp = () => {
    if (otp.length !== 5) {
      setError("OTP must be 5 characters long.");
      return;
    }
    setError("");
    setContentShow("passwordSection");
  };

  const handleResetPassword = () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }
    setError("");
    setContentShow("successSection");
  };

  const closePopup = () => {
    setPasswordPopup(false);
    setContentShow("emailSection");
    setEmail("");
    setOtp("");
    setPassword("");
    setConfirmPassword("");
    setError("");
  };
  return (
    <div className={style.container}>
      <div className={style.profileSection}>
        <div className={style.header}>
          <p>Profile</p>
        </div>
        <div className={style.body}>
          <div className={style.bodyLeft}>
            <div className={style.avatar}>
              <img src={ProfileImage} alt="avatar" />
            </div>
            <div className={style.avatarText}>
              <p>Change Avatar</p>
            </div>
          </div>
          <div className={style.bodyRight}>
            <div className={style.rightTop}>
              <div className={style.dataContainer}>
                <div className={style.dataCard}>
                  <span>First Name</span>
                  {editMode ? (
                    <input
                      type="text"
                      name="firstName"
                      value={profileData.firstName}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{profileData.firstName}</p>
                  )}
                </div>
                <div className={style.dataCard}>
                  <span>Last Name</span>
                  {editMode ? (
                    <input
                      type="text"
                      name="lastName"
                      value={profileData.lastName}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{profileData.lastName}</p>
                  )}
                </div>
              </div>
              <div className={style.dataContainer}>
                <div className={style.dataCard}>
                  <span>User Name</span>
                  {editMode ? (
                    <input
                      type="text"
                      name="userName"
                      value={profileData.userName}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{profileData.userName}</p>
                  )}
                </div>
                <div className={style.dataCard}>
                  <span>Country</span>
                  {editMode ? (
                    <input
                      type="text"
                      name="country"
                      value={profileData.country}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{profileData.country}</p>
                  )}
                </div>
              </div>
              <div className={style.dataContainer}>
                <div className={style.dataCard}>
                  <span>Email</span>
                  {editMode ? (
                    <input
                      type="text"
                      name="email"
                      value={profileData.email}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{profileData.email}</p>
                  )}
                </div>
              </div>
              <div className={style.editSection}>
                <div onClick={toggleEditMode} className={style.editText}>
                  {editMode ? (
                    <button>Save</button>
                  ) : (
                    <>
                      {" "}
                      <MdModeEdit />
                      <p>Edit</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={style.rightBottom}>
              <p onClick={() => setPasswordPopup(true)}>Reset Password</p>
            </div>
          </div>
        </div>
      </div>
      {passwordPopup ? (
        <>
          <div className={style.popupOverlay} onClick={closePopup}></div>
          <div className={style.popup}>
            {contentShow === "emailSection" ? (
              <div className={style.cardContent}>
                <div className={style.cardHeader}>
                  <h4>Reset Password</h4>
                  <span>
                    Receive a code in your email to reset your password.
                  </span>
                </div>
                <div className={style.cardBody}>
                  <div className={style.emailInput}>
                    <input
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  {error && <p className={style.error}>{error}</p>}
                  <div className={style.cardButton}>
                    <button onClick={handleSendEmail}>Send</button>
                  </div>
                  <div className={style.cardBottomText}>
                    <p>
                      Back to <span>Login</span>
                    </p>
                  </div>
                </div>
              </div>
            ) : contentShow === "otpSection" ? (
              <div className={style.cardContent}>
                <div className={style.cardHeader}>
                  <h4>Reset Password</h4>
                  <span>Enter the verification code below.</span>
                </div>
                <div className={style.cardBody}>
                  <div className={style.otpSection}>
                    {[...Array(5)].map((_, i) => (
                      <div key={i} className={style.otpInput}>
                        <input
                          maxLength={1}
                          required
                          onChange={(e) =>
                            setOtp(
                              (prev) =>
                                prev.substring(0, i) +
                                e.target.value +
                                prev.substring(i + 1)
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {error && <p className={style.error}>{error}</p>}
                  <div className={style.cardButton}>
                    <button onClick={handleVerifyOtp}>Send</button>
                  </div>
                  <div className={style.cardBottomText}>
                    <p>
                      Didn’t receive code? <span>Resend</span>
                    </p>
                  </div>
                </div>
              </div>
            ) : contentShow === "passwordSection" ? (
              <div className={style.cardContent}>
                <div className={style.cardHeader}>
                  <h4>Reset Password</h4>
                  <span>Enter a new, strong password!</span>
                </div>
                <div className={style.cardBody}>
                  <div className={style.emailInput}>
                    <input
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className={style.emailInput}>
                    <input
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                  {error && <p className={style.error}>{error}</p>}
                  <div className={style.passwordCondition}>
                    <div className={style.specialCharacter}>*</div>
                    <div className={style.passwordConditionText}>
                      <p>
                        At least 8 characters, 1 uppercase letter,{" "}
                        <span>1 number</span> and{" "}
                        <span>1 special character.</span>
                      </p>
                    </div>
                  </div>
                  <div className={style.cardButton}>
                    <button onClick={handleResetPassword}>Save</button>
                  </div>
                </div>
              </div>
            ) : contentShow === "successSection" ? (
              <div className={style.successContent}>
                <div className={style.successImage}>
                  <img src={SuccessImage} alt="Success" />
                </div>
                <div className={style.successText}>
                  <p>Your password has been changed successfully.</p>
                </div>
                <div className={style.successButton}>
                  <button onClick={closePopup}>Done</button>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Profile;
