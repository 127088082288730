import React, { useState } from "react";
import LockImage from "../../assests/tiny-people-carrying-key.png";
import SuccessImage from "../../assests/PasswordSuccess.png";
import style from "./EmailVerification.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../Url";

const EmailVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.Email;
  const [contentShow, setContentShow] = useState("otpSection");
  const [otp, setOTP] = useState("");

  async function userValidation() {
    const body = {
      email: email,
      otp: otp,
    };

    await fetch(`${baseurl}/otp/validate/user`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
            setContentShow("successSection")
            localStorage.setItem(
              "access-token",
              JSON.stringify(data.data[0]?.access_token)
            );
            localStorage.setItem("ClubID", data.data[0]?.club_id);
            localStorage.setItem("ORGID", data.data[0]?.org_id);
            localStorage.setItem("USERID", data.data[0]?.user_id);
            localStorage.setItem("mentor", data.data[0]?.is_mentor);
            localStorage.setItem("username", data.data[0]?.Username);
            setTimeout(() => {
                navigate("/subscribe")
                
            }, 2000);
        } else if (data.status === "error" || data.status === "failed") {
        }
      });
  }
  return (
    <div className={style.container}>
      <div className={style.contentLeft}>
        <div className={style.resetCard}>
          {contentShow === "otpSection" ? (
            <div className={style.cardContent}>
              <div className={style.cardHeader}>
                <h4>Verification</h4>
                <span>Enter the verification code below.</span>
              </div>
              <div className={style.cardBody}>
                <div className={style.otpSection}>
                  {[...Array(5)].map((_, i) => (
                    <div key={i} className={style.otpInput}>
                      <input
                        maxLength={1}
                        required
                        onChange={(e) =>
                          setOTP(
                            (prev) =>
                              prev.substring(0, i) +
                              e.target.value +
                              prev.substring(i + 1)
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className={style.cardButton}>
                  <button
                    onClick={() => {
                      userValidation();
                    }}
                  >
                    Send
                  </button>
                </div>
                <div className={style.cardBottomText}>
                  <p>
                    Didn’t receive code? <span>Resend</span>
                  </p>
                </div>
              </div>
            </div>
          ) : contentShow === "successSection" ? (
            <div className={style.successContent}>
              <div className={style.successImage}>
                <img src={SuccessImage} alt="Success" />
              </div>
              <div className={style.successText}>
                <p>Email verified successfully.</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={style.contentRight}>
        <div className={style.rightImage}>
          <img src={LockImage} alt="Password reset" />
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
