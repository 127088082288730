import React from "react";
import { useNavigate } from "react-router-dom";
import style from "./Subscription.module.css";
import tick from "../../assests/tick.png";

function Subscription() {
  const navigate = useNavigate();

  return (
    <div className={style.Container}>
      <div className={style.mainBody}>
        <div className={style.user}>
          <p>
            {" "}
            Take a new step <span>John!</span>{" "}
          </p>
        </div>

        <div className={style.course}>
          <p> Courses Available : </p>
        </div>

        <div className={style.cardContainer}>
          <div className={style.courseCard}>
            <div className={style.CardHeader}>
              <div className={style.package}>
                <p>
                  01 <span>GrammarLocker</span>
                </p>
              </div>
              <div className={style.slogan}>
                <p>
                  The ultimate online, interactive English grammar workbook.
                </p>
              </div>
            </div>

            <div className={style.CardBody}>
             <div className={style.pointsHeader}> <p> What’s Included? </p></div>

              <div className={style.points}>
                <img src={tick} alt="tick" />
                <p>
                  <span>Unlimited access</span> to English grammar lessons
                </p>
              </div>
              <div className={style.points}>
                <img src={tick} alt="tick" />
                <p>
                  Various <span>types of assesments</span>{" "}
                </p>
              </div>
              <div className={style.points}>
                <img src={tick} alt="tick" />
                <p>
                  <span>Activities</span> that can enhance your skill
                </p>
              </div>
              <div className={style.points}>
                <img src={tick} alt="tick" />
                <p>
                  <span>Daily drills </span>and <span>weekly workouts</span> to
                  upgrade your skill
                </p>
              </div>
            </div>
          </div>

          <div className={style.subscribeCard}>
            <div className={style.subscribeCardBox}>
              <div className={style.subscribeCardHead}>
                <div className={style.summary}>
                  <p className={style.summaryTitle}>Order Summary</p>
                  <p className={style.summarySpan}>
                    Full access to GrammarLocker
                  </p>
                </div>

                <div className={style.amount}>
                  <p className={style.amountTitle}>Sub Total:</p>
                  <p className={style.amountSpan}>
                    {" "}
                    INR <span>699</span>
                  </p>
                </div>
              </div>

              <div className={style.declare}>
                <label>
                  <input type="checkbox" name="remember" /> &nbsp;
                  <p>I accept all the terms and conditions</p>
                </label>
              </div>

              <div className={style.SubscriptionBtn}>
                <button onClick={() => navigate("/subscribe/success")} >Subscribe Now </button>
              </div>

              <div className={style.separator}>
                <span>OR</span>
            </div>

            <div className={style.startTrail}>
              <p> Unlock your potential with a free trial of Grammar Locker </p>
              
                <button onClick={() => navigate("/start")}>Start Trial </button>
              </div>

            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
