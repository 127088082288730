import React from "react";
import style from "./Fail.module.css";
import FailImg from "../../assests/fail.png";

function Fail() {
  return (
    <div className={style.Container}>
      <div className={style.FailCard}>
        <div className={style.cardBody}>
          <div className={style.Cardheading}>
            <h3>
              Oh no <span>John!</span>{" "}
            </h3>

            <p> Your payment has failed! </p>
          </div>

          <div className={style.FailImg}>
            <img src={FailImg} alt="Success Img " />
          </div>

          <div className={style.Failmsg}>
            <p> Please review your payment details and try again. </p>
            <p>
              If you continue to experience issues, our customer support team is
              here to assist you.
            </p>
          </div>

          <div className={style.try}>
            <button>Try again</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fail;
