import React from 'react'
import style from './InnerHeadBar.module.css'
import EnglishWagonLogo from "../../assests/English wagon logo_Actual 1.png";
import NotificationICon from "../../assests/26. Notification.png"
import MessageICon from "../../assests/Inbox.png";


const InnerHeadBar = () => {
  return (
    <div className={style.Container}>
    <div className={style.topBar}>
      <div className={style.topImg}>
        <img src={EnglishWagonLogo} alt="English Wagon Logo" />
      </div>

      <div className={style.topBarMenu}>
      <div className={style.notificationIconImage}>
          <img src={MessageICon} alt='Inbox'/>
        </div>
        <div className={style.iconImage}>
          <img src={NotificationICon} alt='notification'/>
        </div>
        <div className={style.userIcon}>
            <span>JD</span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default InnerHeadBar