import React, { useEffect, useState, useRef } from "react";
import style from "./DailyDrill.module.css";
import { useLocation } from "react-router-dom";
import { baseurl } from "../Url";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Calendar from "../../assests/activeCalendar.png";
import TimerImage from "../../assests/Chronometer.png";
import { Doughnut } from "react-chartjs-2";
import TrophyImage from "../../assests/Trophy.png";
import { FaCheck, FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";

const DailyDrill = () => {
  const location = useLocation();

  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  const [assessment, setAssessment] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [answers, setAnswers] = useState([]);
  const [answerDetails, setAnswerDetails] = useState([]);
  const [validateClicked, setValidateClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [questionStatus, setQuestionStatus] = useState({});
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const [progress, setProgress] = useState(1);
  const [result, setResult] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(20);
  const [isExpand, setIsExpand] = useState(true);

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();

  const numbers = Array.from(
    { length: assessment.length },
    (_, index) => index + 1
  );

  const handleOptionClick = (option, index) => {
    setAnswers([option, ...answers.slice(1)]);
    setSelectedOptions((prev) => ({
      ...prev,
      [currentAssessment.question_no]: index,
    }));
  };

  const handleInputChange = (index, value) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index] = value;
      return updatedAnswers;
    });
  };

  const formatQuestionText = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {index > 0 && <br />}
        {line}
      </React.Fragment>
    ));
  };

  const renderQuestionWithInputs = (question) => {
    const parts = question.split(/\[blank (\d+)\]/);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const blankIndex = parseInt(parts[index], 10) - 1;
        return (
          <React.Fragment key={index}>
            <input
              type="text"
              value={answers[blankIndex] || ""}
              onChange={(e) => handleInputChange(blankIndex, e.target.value)}
              className={style.blankInput}
            />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={index}>
            {formatQuestionText(part)}
          </React.Fragment>
        );
      }
    });
  };

  const renderQuestionWithDropdowns = (
    question,
    answers,
    setAnswers,
    options
  ) => {
    const parts = question.split(/\[blank (\d+)\]/);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const blankIndex = parts[index];
        return (
          <React.Fragment key={index}>
            <select
              value={answers[blankIndex] || ""}
              onChange={(e) => {
                const newAnswers = { ...answers, [blankIndex]: e.target.value };
                setAnswers(newAnswers);
              }}
              className={style.blankDropdown}
            >
              <option disabled value="">
                Select an option
              </option>
              {options.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={index}>
            {formatQuestionText(part)}
          </React.Fragment>
        );
      }
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;

    if (
      sourceDroppableId.startsWith("left") &&
      destinationDroppableId.startsWith("left")
    ) {
      const items = Array.from(leftItems);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLeftItems(items);
    } else if (
      sourceDroppableId.startsWith("right") &&
      destinationDroppableId.startsWith("right")
    ) {
      const items = Array.from(rightItems);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setRightItems(items);
    }
  };

  const handleArrangeDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(answers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAnswers(items);
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
    setAnswers([]);
    setAnswerDetails([]);
    setValidateClicked(false);
  };

  async function assessmentGet() {
    await fetch(`${baseurl}/fetch/daily/weakly/exams`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: USERID,
        date: currentDate,
        type: "daily",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const questionsWithNumbers = data.data[0]?.questions.map(
          (question, index) => ({
            ...question,
            question_no: index + 1,
            ...(question.type === "Match the following" && {
              draggable: "right",
            }),
          })
        );
        setAssessment(questionsWithNumbers);
      });
  }

  useEffect(() => {
    assessmentGet();
  }, [location.state]);

  useEffect(() => {
    if (assessment && assessment.length > 0) {
      setCurrentAssessment(assessment[currentIndex]);
    }
  }, [assessment, currentIndex]);

  useEffect(() => {
    console.log(currentAssessment, "current");
    if (currentAssessment.type === "Match the following") {
      setLeftItems(currentAssessment.left);
      setRightItems(currentAssessment.right);
    } else if (currentAssessment.type === "Re arrange") {
      setAnswers(currentAssessment.options);
    }
  }, [currentAssessment]);

  useEffect(() => {
    if (
      currentAssessment.question_no &&
      !questionStatus[currentAssessment.question_no]
    ) {
      setQuestionStatus((prev) => ({
        ...prev,
        [currentAssessment.question_no]: "viewed",
      }));
    }
  }, [currentAssessment, questionStatus]);

  return (
    <div className={style.container}>
      <div className={style.assessmentSection}>
        <div className={style.header}>
          <div className={style.headerText}>
            <span>Daily Drill</span>
          </div>
          <div className={style.headerIcon}>
            <img src={Calendar} alt="calendar" />
          </div>
        </div>
        <div className={style.progressBarSection}>
          <div
            className={style.progressBar}
            style={{
              width: "95%",
              backgroundColor: "#8E88903D",
              borderRadius: "2rem",
            }}
          >
            <div
              style={{
                width: `${progress}%`,
                backgroundColor: "#595BDB",
                height: "12px",
                transition: "width 0.5s ease-in-out",
                borderRadius: "1rem",
              }}
            ></div>
          </div>
          <div className={style.progressBarCount}>
            <p>0/15</p>
          </div>
        </div>
        {currentAssessment.question_no ? (
          <div className={style.body}>
            <div className={style.bodyLeft}>
              {result ? (
                <>
                  <div className={style.resultSection}>
                    <div className={style.resultSectionHeder}>
                      <h4>Congratulations!</h4>
                      <p>
                        You’ve done a great job in the test. Keep up the good
                        work.
                      </p>
                    </div>
                    <div className={style.resultSectionImage}>
                      <img src={TrophyImage} alt="Winner" />
                    </div>
                    <div className={style.resultSectionBottomContent}>
                      <p>You’ve scored</p>
                      <h4>13</h4>
                    </div>
                  </div>
                  <div className={style.finalAnswerSection}>
                    <div className={style.finalAnswerSectionHeader}>
                      <p>Answers:</p>
                    </div>
                    <div className={style.finalAnswerSectionBody}>
                      <div className={style.finalAnswerBodyData}>
                        <div className={style.answerBodyHeader}>
                          <div className={style.answerBodyHeaderLeft}>
                            <FaCheck style={{ color: "#00DFC7" }} />
                            <span>1.</span>
                            <p>The Dog</p>
                          </div>
                          <div className={style.answerBodyHeaderRight}>
                            <FaChevronDown />
                          </div>
                        </div>
                      </div>
                      <div className={style.finalAnswerBodyData}>
                        <div className={style.answerBodyHeader}>
                          <div className={style.answerBodyHeaderLeft}>
                            <FaCheck style={{ color: "#00DFC7" }} />
                            <span>2.</span>
                            <p>herself</p>
                          </div>
                          <div className={style.answerBodyHeaderRight}>
                            <FaChevronDown />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${style.finalAnswerBodyData} ${
                          isExpand ? style.expanded : ""
                        }`}
                      >
                        <div
                          style={{ border: "none" }}
                          className={style.answerBodyHeader}
                        >
                          <div className={style.answerBodyHeaderLeft}>
                            <IoMdClose style={{ color: "#FF7C8E" }} />
                            <span>3.</span>
                            <p>Did you ever drop</p>
                          </div>
                          <div className={style.answerBodyHeaderRight}>
                            <FaChevronUp />
                          </div>
                        </div>
                        <div className={style.answerBodyData}>
                          <div className={style.answerBodyQuestion}>
                            <h4>Question</h4>
                            <p>
                              Put the verbs into the present perfect using the
                              adverb of time in the correct position. _____ a
                              knife in the kitchen? (you ever drop)
                            </p>
                          </div>
                          <div className={style.answerBodyCorrectAnswer}>
                            <h4>Correct answer</h4>
                            <p>Have you ever dropped a knife in the kitchen?</p>
                            <p>
                              The structure of the question form using ‘ever’
                              is: have (as the auxiliary for the second person
                              singular or plural) + subject + ever + dropped (as
                              the past participle of the regular verb ‘drop’)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={style.finalAnswerBodyData}>
                        <div className={style.answerBodyHeader}>
                          <div className={style.answerBodyHeaderLeft}>
                            <FaCheck style={{ color: "#00DFC7" }} />
                            <span>4.</span>
                            <p>eaten</p>
                          </div>
                          <div className={style.answerBodyHeaderRight}>
                            <FaChevronDown />
                          </div>
                        </div>
                      </div>
                      <div className={style.finalAnswerBodyData}>
                        <div className={style.answerBodyHeader}>
                          <div className={style.answerBodyHeaderLeft}>
                            <FaCheck style={{ color: "#00DFC7" }} />
                            <span>5.</span>
                            <p>
                              have never
                              visited,went,visited,spent,hiked,flew,were,have
                              ever had.
                            </p>
                          </div>
                          <div className={style.answerBodyHeaderRight}>
                            <FaChevronDown />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={style.questionSection}>
                  <div className={style.question}>
                    <span>{currentAssessment.question_no}.</span>
                    {currentAssessment.type === "fill in the blanks" ? (
                      <p>
                        {renderQuestionWithInputs(
                          currentAssessment.question,
                          answers,
                          setAnswers
                        )}
                      </p>
                    ) : currentAssessment.type === "selection" ? (
                      <p>
                        {renderQuestionWithDropdowns(
                          currentAssessment.question,
                          answers,
                          setAnswers,
                          currentAssessment.options
                        )}
                      </p>
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: currentAssessment.question,
                        }}
                      ></p>
                    )}
                  </div>
                  <div className={style.questionBody}>
                    {currentAssessment.type === "ordinary" ? (
                      <div className={style.ordinaryContainer}>
                        <div className={style.ordinarySection}>
                          <div className={style.ordinarySectionRight}>
                            <input
                              onChange={(e) => {
                                setAnswers([e.target.value]);
                              }}
                              value={answers[0]}
                            />
                          </div>
                        </div>
                      </div>
                    ) : currentAssessment.type === "multiple choices" ? (
                      <div className={style.multipleChoiceContainer}>
                        {currentAssessment.options?.map((option, index) => {
                          const selectedIndex =
                            selectedOptions[currentAssessment.question_no];
                          const isSelected = selectedIndex === index;
                          const status =
                            questionStatus[currentAssessment.question_no];

                          let optionClass = "";
                          if (isSelected) {
                            optionClass =
                              status === "correct"
                                ? style.correct
                                : status === "incorrect"
                                ? style.incorrect
                                : style.selected;
                          } else {
                            optionClass = style.unselected;
                          }

                          return (
                            <div
                              key={index}
                              onClick={() => handleOptionClick(option, index)}
                              className={`${style.multipleChoiceAnswer} ${optionClass}`}
                            >
                              <p>{option}</p>
                            </div>
                          );
                        })}
                      </div>
                    ) : currentAssessment.type === "Match the following" ? (
                      <div className={style.matchFollowingContainer}>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          {leftItems.length > 0 &&
                            leftItems.map((leftData, i) => (
                              <React.Fragment key={i}>
                                <Droppable droppableId={`left-${i}`}>
                                  {(provided) => (
                                    <div
                                      className={style.matchFollowingLeft}
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      <Draggable
                                        draggableId={`left-${i}`}
                                        index={i}
                                        isDragDisabled={
                                          currentAssessment.draggable ===
                                          "right"
                                        }
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={style.matchFollowingCard}
                                          >
                                            <p>{leftData}</p>
                                          </div>
                                        )}
                                      </Draggable>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>

                                <Droppable droppableId={`right-${i}`}>
                                  {(provided) => (
                                    <div
                                      className={style.matchFollowingRight}
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      <Draggable
                                        draggableId={`right-${i}`}
                                        index={i}
                                        isDragDisabled={
                                          currentAssessment.draggable === "left"
                                        }
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={style.matchFollowingCard}
                                          >
                                            <p>{rightItems[i]}</p>
                                          </div>
                                        )}
                                      </Draggable>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </React.Fragment>
                            ))}
                        </DragDropContext>
                      </div>
                    ) : currentAssessment.type === "Re arrange" ? (
                      <DragDropContext onDragEnd={handleArrangeDragEnd}>
                        <Droppable
                          droppableId="rearrange"
                          direction="horizontal"
                        >
                          {(provided) => (
                            <div
                              className={style.reArrangeContainer}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {answers.map((option, index) => (
                                <Draggable
                                  key={index}
                                  draggableId={`option-${index}`}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={style.reArrangeCard}
                                    >
                                      <p>{option}</p>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={style.questionButtonSection}>
                    <div className={style.questionButtonLeft}>
                      {currentAssessment.type === "Match the following" ? (
                        <span>*Drag and place the answer.</span>
                      ) : (
                        ""
                      )}
                      <p
                        onClick={() => {
                          if (currentIndex !== assessment.length - 1) {
                            handleNext();
                          }
                        }}
                      >
                        Skip
                      </p>
                    </div>
                    <div className={style.questionButtonRight}>
                      {answerDetails.length > 0 ? (
                        <button
                          onClick={() => handleNext()}
                          disabled={currentIndex === assessment.length - 1}
                          style={{ background: "#00CBF480" }}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          disabled={answers.length < 0 || validateClicked}
                          onClick={() => {
                            setValidateClicked(true);
                          }}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={style.bodyRight}>
              {result ? (
                <div className={style.summeryContainer}>
                  <div className={style.summeryTopContent}>
                    <div className={style.roundGraph}>
                      <Doughnut
                        data={{
                          labels: ["Completed", "Remaining"],
                          datasets: [
                            {
                              data: [
                                completionPercentage,
                                100 - completionPercentage,
                              ],
                              backgroundColor: ["#03CD09", "#3E3D4212"],
                              borderWidth: 0,
                            },
                          ],
                        }}
                        options={{
                          cutout: "85%",
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                        }}
                      />
                      <div
                        className={style.graphText}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          pointerEvents: "none",
                        }}
                      >
                        {completionPercentage}%
                      </div>
                    </div>
                    <div className={style.summeryTopText}>
                      <p>% of marks acquired</p>
                    </div>
                  </div>
                  <div className={style.summeryBottomContent}>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#00DFC7" }}>13</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Correct Answers</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#FF2443" }}>1</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Wrong Answers</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#FFBE00" }}>1</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Skipped Answers</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#595BDB" }}>15</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Total Answers</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={style.timeContainer}>
                    <div className={style.timerIcon}>
                      <img src={TimerImage} alt="Timer" />
                    </div>
                    <div className={style.timerText}>
                      <p>09:56 mins</p>
                    </div>
                  </div>
                  <div className={style.markContainer}>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Attended</p>
                      </div>
                      <div
                        style={{ marginBottom: "8px" }}
                        className={style.markDataRight}
                      >
                        <p>
                          <span>8</span>/40
                        </p>
                      </div>
                    </div>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Unattended</p>
                      </div>
                      <div className={style.markDataRight}>
                        <p>0</p>
                      </div>
                    </div>
                  </div>
                  <div className={style.questionPaletteHeader}>
                    <p>Question Palette:</p>
                  </div>
                  <div className={style.questionPalette}>
                    {numbers.map((number, index) => {
                      const questionId = assessment[index]?.question_no;
                      const status = questionStatus[questionId];

                      let backgroundColor;
                      if (currentIndex === index) {
                        backgroundColor = "#D9D9D9";
                      } else if (status === "viewed") {
                        backgroundColor = "#818085";
                      } else {
                        backgroundColor = "#FFFFFF";
                      }

                      const numberStyle = {
                        backgroundColor,
                      };

                      return (
                        <div
                          onClick={() => {
                            setCurrentIndex(number - 1);
                            setAnswers([]);
                            setAnswerDetails([]);
                            setValidateClicked(false);
                          }}
                          key={number}
                          className={style.number}
                          style={numberStyle}
                        >
                          <p> {number}</p>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          "No assessment mapped yet"
        )}
      </div>
    </div>
  );
};

export default DailyDrill;
