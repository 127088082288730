import React, { useState, useEffect } from "react";
import style from "./NewLearningDetail.module.css";
import LeftArrow from "../../assests/LeftArrow.png";
import { useLocation,useNavigate } from "react-router-dom";
import { baseurl } from "../Url";

const NewLearningDetail = () => {

  const location = useLocation();
  const navigate =useNavigate();

  const state = location.state;
  const Topic = state?.singleTopic;
  const learning_id = state?.learning;
  const admin_Mapped_Lessons = state?.mapped;
  const main_topic_id = state?.mainId;
  const topicNo = state?.topic_no;

  const key = JSON.parse(localStorage.getItem("access-token"));
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  const [AiId, setAiID] = useState("");
  const [topicData, setTopicData] = useState({});

  async function Topic_Single() {
    await fetch(`${baseurl}/ai/lesson/generation/for/user`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        learning_id: learning_id,
        main_topic_id: main_topic_id,
        user_id: USERID,
        admin_mapped: admin_Mapped_Lessons,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "initial data");
        setAiID(data.data[0]?.ai_id);
      });
  }

  async function dataGet() {
    await fetch(`${baseurl}/lesson/pagination`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        ai_id: AiId,
        user_id: USERID,
        page_num: 1,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setTopicData(data.data?.[0]);
      });
  }

  useEffect(() => {
    Topic_Single();
  }, [location.state]);

  useEffect(() => {
    if (AiId !== undefined||AiId!=="") {
      dataGet();
    }
  }, [AiId]);

  return (
    <div className={style.container}>
      <div className={style.learningBody}>
        <div className={style.header}>
          <div className={style.headerIcon}>
            <img src={LeftArrow} alt="Left" />
          </div>
          <div className={style.headerText}>
            <span>Topic {topicNo}</span>
            <h4>{Topic.value}</h4>
          </div>
        </div>
        <div className={style.learningSection}>
          <div className={style.learningContent}>
            <p
              dangerouslySetInnerHTML={{
                __html: topicData?.text ? topicData.text : "",
              }}
            ></p>
          </div>
          <div className={style.assessmentFloatingSection}>
            <div className={style.floatingSectionLeft}>
              <span>Previous Score:</span>
              <p>0/25</p>
            </div>
            <div className={style.floatingSectionRight}>
              <button onClick={()=>{
                navigate("/assessments",{
                  state:{
                    aiID:AiId,
                    singleTopic:Topic,
                    mapped:admin_Mapped_Lessons,
                    learning:learning_id,
                    mainID:main_topic_id,
                    topicNo:topicNo
                  }
                })
              }}>Take a Test</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLearningDetail;
