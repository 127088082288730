import React, { useState, useEffect } from "react";
import style from "./Assessment.module.css";
import { useLocation } from "react-router-dom";
import { baseurl } from "../Url";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Assessment = () => {
  const location = useLocation();
  const state = location.state;
  const Topic = state?.singleTopic;
  const learning_id = state?.learning;
  const admin_Mapped_Lessons = state?.mapped;
  const main_topic_id = state?.mainID;
  const topicNo = state?.topicNo;
  const aiID = state?.aiID;

  const key = JSON.parse(localStorage.getItem("access-token"));
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  const [assessment, setAssessment] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [validateClicked, setValidateClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [assessmentData, setAssessmentData] = useState({});

  const numbers = Array.from(
    { length: assessment.length },
    (_, index) => index + 1
  );

  const handleOptionClick = (option, index) => {
    const uniqueId = currentAssessment.unique_id;
    const isMultiSelect = currentAssessment.isMultiSelect;

    setAssessmentData((prev) => {
        const prevAnswers = prev[uniqueId]?.answers || [];
        let newAnswers;

        if (isMultiSelect) {
            if (prevAnswers.includes(option)) {
                newAnswers = prevAnswers.filter((ans) => ans !== option);
            } else {
                newAnswers = [...prevAnswers, option];
            }
        } else {
            newAnswers = [option];
        }

        return {
            ...prev,
            [uniqueId]: {
                ...prev[uniqueId],
                answers: newAnswers,
            },
        };
    });

    setSelectedOptions((prev) => {
        if (isMultiSelect) {
            const selectedIndexes = prev[uniqueId] || [];
            return {
                ...prev,
                [uniqueId]: selectedIndexes.includes(index)
                    ? selectedIndexes.filter((i) => i !== index)
                    : [...selectedIndexes, index],
            };
        } else {
            return {
                ...prev,
                [uniqueId]: [index],  
            };
        }
    });
};



  const handleInputChange = (index, value) => {
    const uniqueId = currentAssessment.unique_id;
    setAssessmentData((prev) => {
      const updatedAnswers = [...(prev[uniqueId]?.answers || [])];
      updatedAnswers[index] = value;
      return {
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          answers: updatedAnswers,
        },
      };
    });
  };
  
  const formatQuestionText = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {index > 0 && <br />}
        {line}
      </React.Fragment>
    ));
  };

  const renderQuestionWithInputs = (question) => {
    const parts = question.split(/\[blank (\d+)\]/);
    const uniqueId = currentAssessment.unique_id;
    const status = assessmentData[uniqueId]?.status; 
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const blankIndex = parseInt(parts[index], 10) - 1; 
        let inputStyle = {};

            if (status === "correct") {
                inputStyle = { color: "#00DFC7" }; 
            } else if (status === "incorrect") {
                inputStyle = { color: "#FF2443" }; 
            }else{
              inputStyle = { color: "#FF7A00" }
            }
        return (
          <React.Fragment key={index}>
            <input
              type="text"
              value={assessmentData[uniqueId]?.answers[blankIndex] || ""}
              onChange={(e) => handleInputChange(blankIndex, e.target.value)}
              className={style.blankInput}
              disabled={assessmentData[currentAssessment.unique_id]?.answered}
              style={inputStyle}
            />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={index}>
            {formatQuestionText(part)}
          </React.Fragment>
        );
      }
    });
  };
  
  const renderQuestionWithDropdowns = (
    question,
    options
  ) => {
    const parts = question.split(/\[blank (\d+)\]/);
    const uniqueId = currentAssessment.unique_id;
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const blankIndex = parts[index];
        return (
          <React.Fragment key={index}>
            <select
              value={assessmentData[uniqueId]?.answers[blankIndex] || ""}
              onChange={(e) => {
                const newAnswers = { ...assessmentData[uniqueId]?.answers, [blankIndex]: e.target.value };
                setAssessmentData((prev) => ({
                  ...prev,
                  [uniqueId]: {
                    ...prev[uniqueId],
                    answers: newAnswers,
                  },
                }));
              }}
              className={style.blankDropdown}
              disabled={assessmentData[currentAssessment.unique_id]?.answered}
            >
              <option disabled value="">Select an option</option>
              {options.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={index}>
            {formatQuestionText(part)}
          </React.Fragment>
        );
      }
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
  
    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;
    const uniqueId = currentAssessment.unique_id;
  
    setAssessmentData((prev) => {
      const prevAnswers = prev[uniqueId]?.answers || { left: [], right: [] };
  
      let newAnswers = { ...prevAnswers };
  
      if (sourceDroppableId.startsWith("left") && destinationDroppableId.startsWith("left")) {
        const items = Array.from(newAnswers.left);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.left = items;
      } else if (sourceDroppableId.startsWith("right") && destinationDroppableId.startsWith("right")) {
        const items = Array.from(newAnswers.right);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.right = items;
      }
  
      return {
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          answers: newAnswers,
        },
      };
    });
  };
  
  
  const handleArrangeDragEnd = (result) => {
    if (!result.destination) return;

    const uniqueId = currentAssessment.unique_id;
    const items = Array.from(assessmentData[uniqueId]?.answers || []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAssessmentData((prev) => ({
      ...prev,
      [uniqueId]: {
        ...prev[uniqueId],
        answers: items,
      },
    }));
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
    setValidateClicked(false);
  };



  async function assessmentGet() {
    await fetch(`${baseurl}/ai/assignment/generation/for/user/new`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        learning_id: learning_id,
        main_topic_id: main_topic_id,
        user_id: USERID,
        admin_mapped: admin_Mapped_Lessons,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const modifiedAssessments = data.data[0]?.assessments.map((assessment) => {
          if (assessment.type === "Match the following") {
            return {
              ...assessment,
              draggable:"right",
            };
          }else if (assessment.type === "multiple choices") {
            const isMultiSelect = assessment.answer.length > 1;
            return {
                ...assessment,
                isMultiSelect,  
            };
        }
          return assessment;
        });
    
        setAssessment(modifiedAssessments);
      });
  }

  async function assessmentAnswer() {
    const questionId = currentAssessment.id
      ? currentAssessment.id.toString()
      : "";
      let answerToSend;
      if (currentAssessment.type === "Match the following") {
          if (currentAssessment.draggable === "right") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.right || [];
          } else if (currentAssessment.draggable === "left") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.left || [];
          }
      } else {
          answerToSend = assessmentData[currentAssessment.unique_id]?.answers || [];
      }
  

    await fetch(`${baseurl}/user/assignment/answers/validation`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        question: currentAssessment.question,
        question_id: questionId,
        answer: answerToSend,
        ai_id: aiID,
        main_topic_id: main_topic_id,
        user_id: USERID,
        question_type: currentAssessment.type,
        options: currentAssessment.options ? currentAssessment.options : [],
        unique_id: currentAssessment.unique_id,
        generated_by: currentAssessment.generation === "admin" ? "admin" : "",
        left: currentAssessment.left ? currentAssessment.left : [],
        right: currentAssessment.right ? currentAssessment.right : [],
        time_taken: "",
        markif_correct: currentAssessment.mark,
        markif_incorrect: currentAssessment.negative_mark,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const newStatus = data.data[0].answer_is === "Correct" ? "correct" : "incorrect";
        const matchFieldExists = data.data[0]?.match !== undefined;
        setAssessmentData((prev) => ({
          ...prev,
          [currentAssessment.unique_id]: {
            ...prev[currentAssessment.unique_id],
            status: newStatus,
            explanations: data.data[0].explanation,
            answered:true,
            ...(matchFieldExists && { match: data.data[0].match }),
          },
        }));
        setValidateClicked(true);
      });
  }

  async function assessmentSummaryDetails() {
    await fetch(`${baseurl}/ai/assignment/summary`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        user_id: USERID,
        ai_id: aiID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.data.length > 0) {
        }
      });
  }


  useEffect(() => {
    assessmentGet();
    assessmentSummaryDetails();
  }, [location.state]);

  useEffect(() => {
    if (assessment.length > 0) {
      const currentAssmnt = assessment[currentIndex];
      const uniqueId = currentAssmnt?.unique_id;
  
      setCurrentAssessment(currentAssmnt);
  
      if (!assessmentData[uniqueId]) {
        let initialAnswers;
  
        if (currentAssmnt.type === "Match the following") {
          initialAnswers = {
            left: currentAssmnt.left || [],
            right: currentAssmnt.right || [],
          };
        } else if (currentAssmnt.type === "Re arrange") {
          initialAnswers = currentAssmnt.options || [];
        } else {
          initialAnswers = [];
        }
  
        setAssessmentData((prev) => ({
          ...prev,
          [uniqueId]: {
            answers: initialAnswers,
            status: "",
            explanations: "",
          },
        }));
      } else if (!assessmentData[uniqueId]?.status) {
        setAssessmentData((prev) => ({
          ...prev,
          [uniqueId]: {
            ...prev[uniqueId],
            status: 'viewed',
          },
        }));
      }
    }
  }, [currentIndex, assessment, assessmentData]);
  
  

  return (
    <div className={style.container}>
      <div className={style.assessmentSection}>
        <div className={style.header}>
          <div className={style.headerText}>
            <span>Topic {topicNo}</span>
            <h4>{Topic.value}</h4>
          </div>
        </div>
        {currentAssessment.id ? (
          <div className={style.body}>
            <div className={style.bodyLeft}>
              <div className={style.questionSection}>
                <div className={style.question}>
                  <span>{currentAssessment.id}.</span>
                  {currentAssessment.type === "fill in the blanks" ? (
                    <p>
                      {renderQuestionWithInputs( currentAssessment.question )}
                    </p>
                  ) : currentAssessment.type === "selection" ? (
                    <p>
                      {renderQuestionWithDropdowns(
                        currentAssessment.question,
                        currentAssessment.options
                      )}
                    </p>
                  ) : (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: currentAssessment.question,
                      }}
                    ></p>
                  )}
                </div>
                <div className={style.questionBody}>
                  {currentAssessment.type === "ordinary" ? (
                    <div className={style.ordinaryContainer}>
                      <div className={style.ordinarySection}>
                        <div className={style.ordinarySectionRight}>
                        <input
                            disabled={assessmentData[currentAssessment.unique_id]?.answered}
                            style={{
                              backgroundColor: assessmentData[currentAssessment.unique_id]?.status === "correct"
                                ? "#00DFC7"
                                : assessmentData[currentAssessment.unique_id]?.status === "incorrect"
                                ? "#FF2443"
                                : "transparent"
                            }}
                            onChange={(e) => {
                              const uniqueId = currentAssessment.unique_id;
                              setAssessmentData((prev) => ({
                                ...prev,
                                [uniqueId]: {
                                  ...prev[uniqueId],
                                  answers: [e.target.value],
                                },
                              }));
                            }}
                            value={assessmentData[currentAssessment.unique_id]?.answers[0] || ""}
                          />
                        </div>
                      </div>
                    </div>
                  ) : currentAssessment.type === "multiple choices" ? (
                    <div className={style.multipleChoiceContainer}>
                      {currentAssessment.options?.filter(option => option.trim() !== "").map((option, index) => {
                         const selectedIndexes = selectedOptions[currentAssessment.unique_id] || [];
                         const isSelected = selectedIndexes.includes(index);
                         const status = assessmentData[currentAssessment.unique_id]?.status;
             
                         let optionClass = "";
                         if (isSelected) {
                             optionClass =
                                 status === "correct"
                                     ? style.correct
                                     : status === "incorrect"
                                     ? style.incorrect
                                     : style.selected;
                         } else {
                             optionClass = style.unselected;
                         }
             
                        return (
                          <div
                            key={index}
                            onClick={() =>{
                              if(assessmentData[currentAssessment.unique_id]?.answered!==true){
                               handleOptionClick(option, index)}}
                              }
                            className={`${style.multipleChoiceAnswer} ${optionClass}`}
                          >
                            <p>{option}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : currentAssessment.type === "Match the following" ? (
                    <div className={style.matchFollowingContainer}>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      {(assessmentData[currentAssessment.unique_id]?.answers?.left || []).map((leftData, i) => {
                         const matchValue = assessmentData[currentAssessment.unique_id]?.match?.[i];
                         let cardClassName;
                         if (matchValue === "1") {
                           cardClassName = style.correctCard;
                         } else if (matchValue === "0") {
                           cardClassName = style.errorCard;
                         } else {
                           cardClassName = style.defaultCard;
                         }
                         return (
                        <React.Fragment key={i}>
                          <Droppable droppableId={`left-${i}`}>
                            {(provided) => (
                              <div
                                className={style.matchFollowingLeft}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <Draggable
                                  draggableId={`left-${i}`}
                                  index={i}
                                  isDragDisabled={
                                    currentAssessment.draggable === "right" ||
                                    assessmentData[currentAssessment.unique_id]?.answered
                                  }
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`${style.matchFollowingCard} ${cardClassName}`}
                                    >
                                      <p>{leftData}</p>
                                    </div>
                                  )}
                                </Draggable>
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                
                          <Droppable droppableId={`right-${i}`}>
                            {(provided) => (
                              <div
                                className={style.matchFollowingRight}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <Draggable
                                  draggableId={`right-${i}`}
                                  index={i}
                                  isDragDisabled={
                                    currentAssessment.draggable === "left" ||
                                    assessmentData[currentAssessment.unique_id]?.answered
                                  }
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`${style.matchFollowingCard} ${cardClassName}`}
                                    >
                                      <p>{(assessmentData[currentAssessment.unique_id]?.answers?.right || [])[i]}</p>
                                    </div>
                                  )}
                                </Draggable>
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </React.Fragment>
                      )})}
                    </DragDropContext>
                  </div>
                  ) : currentAssessment.type === "Re arrange" ? (
                    <DragDropContext onDragEnd={handleArrangeDragEnd}>
                      <Droppable droppableId="rearrange" direction="horizontal">
                        {(provided) => (
                          <div
                            className={style.reArrangeContainer}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {assessmentData[currentAssessment.unique_id]?.answers.map((option, index) => (
                              <Draggable
                                key={index}
                                draggableId={`option-${index}`}
                                index={index}
                                isDragDisabled={assessmentData[currentAssessment.unique_id]?.answered}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={style.reArrangeCard}
                                  >
                                    <p>{option}</p>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )  : (
                    ""
                  )}
                </div>
                <div className={style.questionButtonSection}>
                  <div className={style.questionButtonLeft}>
                    {currentAssessment.type === "Match the following" ?<span>*Drag and place the answer.</span>:""}
                    <p onClick={() =>{
                      if(currentIndex !== assessment.length - 1){
                        handleNext()
                      }
                    }}
                        >Skip</p>
                  </div>
                  <div className={style.questionButtonRight}>
                    {assessmentData[currentAssessment.unique_id]?.answered ? (
                      <button
                        onClick={() => handleNext()}
                        disabled={currentIndex === assessment.length - 1}
                        style={{ background: "#00CBF480" }}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        disabled={assessmentData[currentAssessment.unique_id]?.answers.length < 0 || validateClicked}
                        onClick={() => {
                          assessmentAnswer();
                          setValidateClicked(true);
                        }}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {assessmentData[currentAssessment.unique_id]?.answered ? (
                <div className={style.explanationSection}>
                  
                      <div  className={style.resultText}>
                        <p  style={{color: assessmentData[currentAssessment.unique_id]?.status === "correct" ? "#00DFC7" : "#FF2443"}}>
                        {assessmentData[currentAssessment.unique_id]?.status === "correct"
                          ? "You are correct!"
                          : "That’s ok! Keep going. "}
                        </p>
                      </div>
                   
                  {validateClicked && assessmentData[currentAssessment.unique_id]?.answered ? (
                    <>
                      <div className={style.explanationHeading}>
                        <p>Explanation:</p>
                      </div>
                      <div className={style.explanationText}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: assessmentData[currentAssessment.unique_id]?.explanations,
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={style.bodyRight}>
              <div className={style.markContainer}>
                <div className={style.markData}>
                  <div className={style.markDataLeft}>
                    <p>Total mark</p>
                  </div>
                  <div
                    style={{ marginBottom: "8px" }}
                    className={style.markDataRight}
                  >
                    <p>
                      <span>8</span>/40
                    </p>
                  </div>
                </div>
                <div className={style.markData}>
                  <div className={style.markDataLeft}>
                    <p>Correct</p>
                  </div>
                  <div className={style.markDataRight}>
                    <p>8</p>
                  </div>
                </div>
                <div className={style.markData}>
                  <div className={style.markDataLeft}>
                    <p>Wrong</p>
                  </div>
                  <div className={style.markDataRight}>
                    <p>0</p>
                  </div>
                </div>
                <div className={style.markData}>
                  <div className={style.markDataLeft}>
                    <p>Unattended</p>
                  </div>
                  <div className={style.markDataRight}>
                    <p>0</p>
                  </div>
                </div>
              </div>
              <div className={style.questionPaletteHeader}>
                <p>Question Palette:</p>
              </div>
              <div className={style.questionPalette}>
                {numbers.map((number, index) => {
                  const questionId = assessment[index]?.unique_id;
                  const status = assessmentData[questionId]?.status;

                  let backgroundColor;
                  if (currentIndex === index) {
                    backgroundColor = "#D9D9D9"; 
                  } else if (status === "correct") {
                    backgroundColor = "#00DFC7";
                  } else if (status === "incorrect") {
                    backgroundColor = "#FF2443";
                  }else if (status === "viewed") {
                    backgroundColor = "#818085";
                  } else {
                    backgroundColor = "#FFFFFF";
                  }

                  const numberStyle = {
                    backgroundColor,
                  };

                  return (
                    <div
                      onClick={() => {
                        setCurrentIndex(number - 1);
                        setValidateClicked(false);
                      }}
                      key={number}
                      className={style.number}
                      style={numberStyle}
                    >
                      <p> {number}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          "No assessment mapped yet"
        )}
      </div>
    </div>
  );
};

export default Assessment;
